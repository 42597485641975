.equipment-container {
  .equipment-status {
    .box {
      padding: 1.5rem;
      border: 1px solid black;
      text-align: center;
      display: inline-grid;
      align-items: center;
      justify-content: center;
      margin: 1rem 0;
    }

    .box:hover {
      background-color: gray;
      cursor: pointer;
    }
  }
}

.main-container {
  .cropper-card {
    .analyze-container {
      height: 75vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .img-vel {
      max-width: 100%;
      margin: 1rem 0;
    }
    .error {
      margin-bottom: 1rem;
    }
    .no-videos {
      img {
        height: 18%;
        margin-bottom: 1rem;
      }
      flex-direction: column;
      display: flex;
      margin: auto;
      justify-content: center;
      height: 100%;
      align-items: center;
      font-size: 1.3rem;
    }
    .datetime-picker {
      width: 190px;
    }
    .info-video-equipment {
      p {
        text-align: left !important;
        margin-left: 1rem;
        .title {
          font-weight: 600 !important;
        }
        .description {
          font-family: "Roboto", sans-serif;
          font-weight: 500 !important;
          font-size: 1.1vw !important;
          color: #5f5f5f;
          margin-left: 0.5rem;
        }
      }
      margin: 1rem 0;
    }
  }
}
