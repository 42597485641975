.account-container {
    background-color: #f7f7f7;
    position: relative;
    overflow:hidden; 
    z-index: 0;

   
}

.account-wrapper {
    display: grid;
    min-height: 100vh;
    grid-template-columns: 0% 1fr 1fr 12%;
    grid-template-rows: 1fr;
    overflow: hidden;

    .illustration {
        grid-column: 1/2;
        grid-row: 1/2;
        object-fit: cover;
        position: absolute;
        left: 0;
        top: 0;
        height: 100vh;
        bottom: 0;
        width: 63%;
        z-index: -1;
    }

    .perfil {
        grid-column: 3;
        text-align: right;
        grid-template-rows: 20% 1fr 2fr 1fr 10%;
        display: grid;
        justify-items: flex-end;

        h1 {
            grid-row: 2/3;
            align-content: flex-end;
            font-family: "DM Sans";
            font-size: 3rem;
            font-weight: bold;
            align-self: flex-end;
        }

        .form-perfil {
            grid-row: 3/4;
            align-content: flex-end;

            .MuiFormControl-root {
                margin: 1rem;
                width: 44%
            }

            .MuiInputBase-input.Mui-disabled {
                color: #4a4a4a;
            }

            .MuiInput-underline:before {
                border-bottom: 1px solid rgb(9 52 174);
            }

            .MuiInput-underline.Mui-disabled:before {
                border-bottom: 1px dotted rgba(0, 0, 0, 0.42);
            }

            .MuiFormHelperText-root.Mui-error {
                text-align: left !important;
            }
        }



        .btn-gris {
            grid-row: 4/5;
            font-size: 1.2rem !important;
            border-radius: 0.5rem !important;
            background-color: #999999 !important;
            text-transform: uppercase;
            padding: 0.4rem 2rem !important;
            margin: 5rem 1rem 0 0;
            color: white;
            max-height: 3rem;
            width: 35%;
            font-family: Roboto;
        }

        .btn-celeste {
            grid-row: 4/5;
            font-size: 1.2rem;
            border-radius: 0.5rem !important;
            background-color: #3f63c7 !important;
            text-transform: uppercase;
            padding: 0.4rem 2rem !important;
            margin: 1rem 0;
            color: white;
            max-height: 3rem;
            width:auto;
            font-family: Roboto;
        }
    }
}

@media(max-width: 1220px){
    .account-wrapper {
        display: flex;
        min-height: 100vh;
       position: relative;
        overflow: hidden;
        padding:1rem;
        .illustration {
            
            object-fit: cover;
            position: absolute;
            left: 0;
            bottom: 0;
            opacity: 0.5;
            height: 137%;
            width: 140%;
            z-index: -1;
        }
    
        .perfil {
           position: relative;
            text-align: left;
            flex-direction: column;
            display: flex;
            justify-items: flex-end;
            margin: 6rem 0 0 0.5rem;
            justify-content: flex-start;
            h1 {
                align-self: flex-start;
                font-family: "DM Sans";
                font-size: 2.5rem;
                font-weight: bold;
                margin-left: 1rem;
                margin-bottom: 2rem;
            }
    
            .form-perfil {
               
                align-content: flex-end;
    
                .MuiFormControl-root {
                    margin: 1rem 0.5rem 0 0 ;
                    width: 47%;
                }
    
                .MuiInputBase-input.Mui-disabled {
                    color: #4a4a4a;
                }
    
                .MuiInput-underline:before {
                    border-bottom: 1px solid rgb(9 52 174);
                }
    
                .MuiInput-underline.Mui-disabled:before {
                    border-bottom: 1px dotted rgba(0, 0, 0, 0.42);
                }
    
                .MuiFormHelperText-root.Mui-error {
                    text-align: left !important;
                }
            }
    
    
            .btn-celeste {
              
                font-size: 1.2rem;
                border-radius: 0.5rem !important;
                background-color: #3f63c7 !important;
                text-transform: uppercase;
                padding: 0.4rem 2rem !important;
                margin: 3rem 0;
                color: white;
                max-height: 3rem;
                width: 100%;
                font-family: Roboto;
            }
        }
    }
}