.payments-container {
  background-color: #f7f7f7;
  position: relative;
  overflow: hidden;
  z-index: 0;
}

.payments-wrapper {
  display: grid;
  min-height: 100vh;
  grid-template-columns: 5% 2fr 1fr 0%;
  grid-template-rows: 1fr;
  overflow: hidden;

  .illustration {
    grid-column: 3/4;
    grid-row: 1/2;
    object-fit: cover;
    position: absolute;
    right: 0;
    top: 0;
    height: 100vh;
    bottom: 0;
    width: 63%;
    z-index: -1;
  }

  .payment {
    grid-column: 2;
    text-align: left;
    grid-template-rows: 20% 0.5fr 2fr 10%;
    display: grid;

    h1 {
      grid-row: 2/3;
      font-family: "DM Sans";
      font-size: 3rem;
      font-weight: bold;
      text-align: left;
    }

    .header {
      text-align: left;
      width: 100%;
      color: rgb(63, 99, 199);
      display: flex;

      h1 {
        font-size: 1.65rem;
        padding: 0;
        margin: 0;
        display: inline-block;
        font-weight: 900;
        font-family: "DM Sans", sans-serif;
        text-align: left;
        margin-bottom: 4rem;
      }
    }

    .actual-payment {
      grid-row: 3/4;
    }

    .form-payment {
      grid-row: 3/4;
      align-content: flex-end;
      margin-left: 1rem;
    }

    .btn-gris {
      grid-row: 4/5;
      font-size: 1.2rem !important;
      border-radius: 0.5rem !important;
      background-color: #999999 !important;
      text-transform: uppercase;
      padding: 0.4rem 2rem !important;
      margin: 5rem 1rem 0 0;
      color: white;
      height: auto;
      width: 35%;
      font-family: Roboto;
    }

    .btn-celeste {
      grid-row: 4/5;
      font-size: 1.2rem;
      border-radius: 0.5rem !important;
      background-color: #3f63c7 !important;
      text-transform: uppercase;
      padding: 0.4rem 2rem !important;
      margin: 5rem 0 0 0;
      color: white;
      height: auto;
      width: 35%;
      font-family: Roboto;
    }
  }
}

.userpayments-container {
  background-color: #f7f7f7;
  position: relative;
  padding: 2rem 4rem;
  margin-top: 55px;
  z-index: 0;

  .background-up {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

  .background-paper {
    position: absolute;
    right: 21%;
    top: 1%;
    height: auto;
    width: auto;
    z-index: -1;
  }
}

.userpayments-wrapper {
  min-height: 84vh;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-family: "Roboto";

  #form {
    width: 100%;
    text-align: left !important;
    padding: 1rem;
  }

  .form-label {
    text-align: left !important;
  }

  .btn-celeste:hover {
    background-color: #3f63c7;
  }

  .header {
    display: grid;
    grid-template-columns: 0% 1fr 1fr 5%;
    margin-bottom: 3rem;

    .text-header {
      grid-column: 2/3;
      text-align: left;

      h1 {
        font-family: "Roboto";
        font-size: 3.1rem;
        font-weight: bold;
        text-align: left;
        margin: 1.5rem 0;
        color: black;
      }

      h2 {
        font-family: "Roboto";
        font-size: 1.5rem;
        text-align: left !important;
        font-weight: 100;
        color: gray;

        .rotate-90 {
          -webkit-transform: rotate(90deg);
          -moz-transform: rotate(90deg);
          -ms-transform: rotate(90deg);
          -o-transform: rotate(90deg);
          transform: rotate(90deg);
        }
      }
    }

    .button-header {
      grid-column: 3/4;
      align-self: center;

      .btn-celeste {
        padding: 0.5rem 2rem;
        font-size: 1.3rem;
      }
    }
  }

  .btn-celeste {
    float: right;
    background-color: #3f63c7;
    color: white;
    padding: 0.3rem 3rem;
    font-size: 1.1rem;
  }

  .subtitle {
    text-align: left;
    width: 100%;
    color: rgb(63, 99, 199);
    display: flex;
    padding: 0;

    h1 {
      font-size: 1.65rem;
      padding: 1rem 10px;
      margin: 0;
      display: inline-block;
      font-weight: 900;
      font-family: "DM Sans", sans-serif;
      text-align: left;
    }

    h2 {
      font-size: 1.4rem;
      margin: 0;
      display: inline-block;
      font-weight: 900;
      font-family: "DM Sans", sans-serif;
      text-align: left;
    }

    .filter-icon {
      margin: 0 0.5rem;
    }
  }

  .show-details {
    font-size: 1.2rem;
    padding: 2rem 5rem;
    color: black;
    background-color: #f7f7f7;

    .approval {
      display: flex;
      flex-direction: column;
    }
  }
}

@media (max-width: 768px) {
  .userpayments-container {
    padding: 2rem;
    .background-paper {
      left: 0;
      top: 10%;
      opacity: 0.5;
    }
  }

  .userpayments-wrapper {
    .header {
      display: flex;
      flex-direction: column;
      .text-header {
        h1 {
          font-size: 2.5rem;
        }
      }
      .button-header {
        margin-top: 1.5rem;
        width: 100%;
        max-width: 100%;
        .btn-celeste {
          width: 100%;
        }
      }
    }
  }
}
