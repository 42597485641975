.review{
    background-color: #f7f7f7;
    position: relative;
    padding: 2rem 4rem;
    margin-top: 55px; 
    min-height: 92.5vh;
    overflow-x: hidden;
    z-index: 0; 

    .wave-right{
        position: absolute;
        right: 0;
        top: 0;
        z-index:-1;
        height: 100%;
    }

    .wave-left{
        position: absolute;
        overflow-y: hidden;
        left: 0;
        top: 0;
        width: 60%;
        z-index:-1;
        height: 130%;
    }
 
}

.review-container {

    .missing-info {
        margin-bottom: 2rem;
        .fs-r{
            width: 8rem;
    align-self: center;
    margin-bottom: 2rem;
    object-fit: contain;
        }
    }

    .btn-container {
        width: 100%;
     
        .btn-celeste {
            padding: 0.5rem 2rem;
            font-size: 1.1rem;
            border-radius: 0.25rem !important;
            float: right;
            background-color: #3f63c7;
            color: white;
            font-family: "Roboto";
            text-transform: uppercase;
            float: right;
        }
        .btn-gris {
            padding: 0.5rem 2rem !important;
            font-size: 1.1rem !important;
            border-radius: 0.25rem !important;
            font-family: "Roboto";
            margin-right: 1rem;
            text-transform: uppercase;
            float: right;
        }
    }

    .step-description {
        text-align: left;
        p {
            color: #3f63c7;
            font-weight: 600;
            font-size: 1.5rem;
            text-align: left !important;
            font-family: "DM Sans" !important;
        }
    }

    .MuiAccordion-root {
        margin-bottom: 2rem;
    }

    .form-control{
        background-color: transparent;
        border: 1px solid #94989c;

    }

    .header {
        margin-bottom: 1rem;
        .text-header {
            text-align: left;

            h1 {
                font-family: "Roboto";
                font-size: 2.5rem;
                font-weight: bold;
                text-align: left;
                margin: 0rem 0 0rem;
                color: #323c58;
            }

            h2{
                font-family: "Dm Sans";
                font-size: 2.5rem;
                font-weight: bold;
                text-align: left;
                margin: 2rem 0 1.5rem;
                color: #3f63c7;
            }
        }
    }

    .header-filter {
        color: #3f63c7;
        h2 {
            font-size: 1.4rem;
            margin: 0;
            display: inline-block;
            font-weight: 900;
            font-family: "DM Sans", sans-serif;
            text-align: left;
        }

        .filter-icon {
            margin: 0 0.5rem;
            vertical-align: bottom;
        }
    }



    .search-button {
        background-color: white;
        color: dimgray;
        border-color: dimgray;
        padding: 0.5rem 0.5rem;
        vertical-align: top;
        border-radius: 0.1rem;
        border-left: none;

        .icon-search {
            vertical-align: middle;
        }
    }

    .search-button:hover,
    .search-button:focus,
    .search-button:active {
        background-color: white !important;
        color: dimgray !important;
        border-color: dimgray !important;
        box-shadow: 0 0 0 0.2rem rgb(221 221 221 / 50%);
    }

    .equipment-wrapper {
        display: flex;
        flex-direction: column;
        max-height: 23rem;

        form {
            input {
                padding: 0.5rem 0.5rem;
                width: 90%;
                vertical-align: top;
            }
        }
    }

    .equipment-list {
        margin: auto;
        width: 95%;
        overflow-y: scroll;
        cursor: pointer;
        
        .equipment-item {
            display: flex;
            padding: 1rem;
            border-bottom: 1px solid gray;
            border-top: 1px solid gray;


            .icon-equip {
                font-size: 2.5rem;
                margin-right: 1rem;
            }

            p {
                font-family: "Roboto";
                align-self: center;
                margin-bottom: 0;
            }
            
            h3{
                margin: 0 !important;
                font-size: 1.1rem !important; 
                text-align: left !important; 
            }
           
        }
    }

    .description-equipment {
        margin: auto;
        background-color: #495575;
        border-radius: 0.5rem;
        border: 1px solid #495575;
        padding: 2rem;
        width: 75%;
        color: #ebebeb; 
        .icon-de{
            font-size: 3.5rem;
        }

        h2 {
            font-size: 1.3rem;
            font-family: Roboto;
            font-weight: bold;
            margin:0 !important
        }

        h3 {
            color: white;
            margin: 0.3rem  0 2rem 0!important;
            font-weight: 400 !important;
            font-size: 1.1rem !important;
        }

        p {
            font-family: "Roboto";
            font-size: 1rem !important;
            text-align: left !important;
            margin-bottom: 0.5rem;
        }
    }
    .form-group {
        text-align: left;
    }

    .form-review {
        padding: 2rem 0rem;
        .btn-celeste {
            padding: 0.5rem 2rem;
            font-size: 1.1rem;
            border-radius: 0.25rem !important;
            float: right;
            background-color: #3f63c7;
            color: white;
            text-transform: uppercase;
        }

        .btn-gris {
            padding: 0.5rem 2rem;
            font-size: 1.1rem !important;
            float: right;
            border-radius: 0.25rem !important;
            margin-right: 1rem;
            background-color: gray;
            color: white;
            text-transform: uppercase;
        }
        .checkbox {
            input[type="checkbox"] {
                width: 1rem;
                height: 1rem;
                background: white;
                border: 2px solid #555;
            }

            label {
                font-size: 1rem !important;
            }
        }
    }
}

#form-filter {
    width: 100%;
    text-align: left !important;
    padding: 1rem;
    .btn-celeste {
        float: right;
        background-color: #3f63c7;
        color: white;
        padding: 0.3rem 3rem;
        font-size: 1.1rem;
        text-transform: uppercase;
        font-family: "Roboto";
    }

    .btn-celeste:hover {
        background-color: #3f63c7;
    }

    .btn-gris {
        margin-right: 1rem;
        border-radius: 0.25rem !important;
        float: right;
        color:white;
    }
}

.iniciada{
    background-color: #e8c100;
}

.finalizada{
    background-color: #179600;
}

.no-realizada{
    background-color: #bd0000;
}

.postergada {
    background-color: blue;
}

.status{
    padding: 0.5rem 1rem;
    border-radius: 3rem;
    color:white;
    font-family: "Roboto";
    font-size: 0.9rem;
    text-transform: uppercase;
}

.header-user{
    .button-header{
        display: flex;
        flex-direction: column;
        max-width: 50%;
        justify-self: flex-end;
        .btn-gris{
            margin-top: 1rem;
            border-radius: 0.25rem !important;
            margin-left: 0rem !important;
            padding: 0.5rem 4.5rem !important;
        }
    }
}

.circle{
    height: 1.5rem;
    align-self: center;
    width: 1.5rem;
    border-radius: 3rem;
}

.references-equipment{
    display: flex;
    margin:1rem 0.5rem;
    .description{
        margin:0 1rem 0 0.5rem;
        align-self: center;
        font-family: Roboto;
        font-weight: 600;
        color: darkslategray;
    }
}