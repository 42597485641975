.statistics-container {
    background-color: #f7f7f7;
    position: relative;
    padding: 2rem 4rem;
    margin-top: 55px; 
    z-index: 0; 
    .wave{
        position: absolute;
        left: 0;
        bottom: 0;
        height: 83%; 
        width: 100%; 
        z-index: -1;
    }
}

.statistics {
    min-height: 84vh;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-family: "Roboto";


    .header {
        text-align: left;
        width: 100%;
        color: rgb(63, 99, 199);
        display: flex;

        h1 {
            font-size: 1.65rem;
            padding: 1rem 10px;
            margin: 0;
            display: inline-block;
            font-weight: 900;
            font-family: 'DM Sans', sans-serif;
            text-align: left;
        }
    }

    .cards {
        display: flex;
        justify-content: space-between;
        color: black;
        margin-bottom: 2rem;

        .card {
            border: 2px solid #3F63C7;
            background: white;
            border-radius: 0.5rem;
            overflow: hidden;
            margin: 0rem 2rem;
            padding: 0.5rem 1rem;
            display: flex;
           width: 100%;
            flex-direction: column;
            text-align: left;

            h5 {
                text-transform: uppercase;
                font-weight: 600;
                color: #505050;
                margin: 0.5rem; 
            }
        }
    }

    .button-user-statistics{
        background-color: white;
        color: #363636;
        margin: 0.5rem 0.5rem;
        padding: 0rem 1rem 0 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        border-radius: 0.75rem;
        font-size: 1.15rem;
        font-weight: 500;
        img{
            max-height: 3.8rem;
            margin-right: 1rem;
        }
    }

    .row-cards{
        background-color: #f7f6ff;
        text-align: left;
        padding: 2rem;
        margin: 0; 
        border-radius: 0.5rem;
        h5{
            margin-bottom: 1rem;
            font-weight: 500;
        }
    }

    .row-users{
        display: flex;
    }
    .show-details{
        font-size: 1.2rem; 
        padding:2rem 5rem;
        color: black;
        background-color: #f7f7f7; 

        .approval{
            display: flex;
            flex-direction:column; 
        }
    }
}

@media(max-width: 768px){
    .statistics{
        .row-users{
           flex-direction: column;
        }
        .button-user-statistics{
            text-align: left;
            font-size: 1.5rem;
            img{
                margin-right: 2rem;
            }
          
        }

        .row-cards{
            padding: 1rem 0;
            h5{
                margin: 1rem 0 !important; 
            }
        }

        .display-drop{
            display: none;
        }

        .cards{
            .card{
                margin:1.5rem 0; 
            }
        }

      
    }
    .background-us{
        display: none;
    }
}