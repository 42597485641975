.user-cards {
  margin-top: 1rem;
}

.user-card {
  display: flex;
  height: 100%;
  justify-content: space-around;

  img {
    max-width: 39%;
    max-height: 80%;
    align-self: center;
  }

  .user-description {
    display: flex;
    flex-direction: column;
    justify-content: center;

    h5 {
      text-align: left;
      text-transform: none !important;
      color: black !important;
      font-weight: 600;
      margin-right: 0 !important;
      margin-left: 0 !important;
      font-size: 1.4rem;
      font-family: "DM Sans";
    }

    .desc {
      p {
        color: #575757;
        text-align: left !important;
        margin-bottom: 0 !important;
        font-family: "DM Sans" !important;
      }
    }
  }
}

.header-user {
  display: grid;
  grid-template-columns: 0% 1fr 1fr 5%;
  margin-bottom: 0rem;

  .text-header {
    grid-column: 2/3;
    text-align: left;

    h1 {
      font-family: "Roboto";
      font-size: 2.8rem;
      font-weight: bold;
      text-align: left;
      margin: 0 0;
      color: #484848;
    }

    h2 {
      font-family: "Roboto";
      font-size: 1.5rem;
      text-align: left !important;
      font-weight: 100;
      color: gray;

      .rotate-90 {
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
      }
    }
  }

  .button-header {
    grid-column: 3/4;
    align-self: center;

    .btn-celeste {
      padding: 0.5rem 1.5rem;
      font-size: 1.3rem;
    }

    .btn-gris {
      color: white;
      padding: 0.5rem 5rem !important;
      font-size: 1.3rem !important;
      float: right;
    }
  }
}

.background-top {
  position: absolute;
  right: 17%;
  top: 1%;
  height: auto;
  width: 50%;
  z-index: -1;
}

.background-us {
  position: absolute;
  right: 17%;
  top: 1%;
  height: auto;
  width: 35%;
  z-index: -1;
}

.accountEdit-wrapper {
  display: grid;
  min-height: 100vh;
  grid-template-columns: 0% 1fr 1.25fr 5%;
  grid-template-rows: 1fr;
  overflow: hidden;

  .illustration {
    grid-column: 1/2;
    grid-row: 1/2;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    height: 100vh;
    bottom: 0;
    width: 63%;
    z-index: -1;
  }

  .form-register {
    grid-row: 3/4;
    text-align: left;
    margin-left: 1rem;
    width: 95%;

    .form-control {
      background-color: transparent;
      border: none;
      border-bottom: 1px solid gray;
      border-radius: 0rem;
    }

    .custom-select {
      background-color: transparent !important;
      border: none;
      border-bottom: 1px solid gray;
      border-radius: 0;
    }

    .form-control:active,
    .form-control:focus {
      border-bottom: 1px solid blue;
    }
  }

  .perfil {
    grid-column: 3;
    text-align: right;
    grid-template-rows: 17% 1fr 2fr 10%;
    display: grid;
    justify-items: flex-end;

    .header-edit {
      grid-row: 2/3;

      h1 {
        align-content: flex-end;
        font-family: "DM Sans";
        font-size: 3rem;
        font-weight: bold;
        align-self: center;
      }

      h2 {
        font-weight: 100;
        color: gray;
        align-content: flex-end;
        font-family: "Roboto";
        font-size: 1.2rem;
        align-self: flex-end;
      }
    }

    .form-perfil {
      grid-row: 3/4;
      align-content: flex-end;

      .MuiFormControl-root {
        margin: 1rem;
        width: 44%;
      }

      .MuiInputBase-input.Mui-disabled {
        color: #4a4a4a;
      }

      .MuiInput-underline:before {
        border-bottom: 1px solid rgb(9 52 174);
      }

      .MuiInput-underline.Mui-disabled:before {
        border-bottom: 1px dotted rgba(0, 0, 0, 0.42);
      }

      .MuiFormHelperText-root.Mui-error {
        text-align: left !important;
      }
    }

    .btn-gris {
      grid-row: 4/5;
      font-size: 1.2rem !important;
      border-radius: 0.5rem !important;
      background-color: #999999 !important;
      text-transform: uppercase;
      padding: 0.4rem 2rem !important;
      margin: 2rem 1rem 0 0;
      color: white;
      height: auto;
      width: 47%;
      font-family: Roboto;
    }

    .btn-celeste {
      grid-row: 4/5;
      font-size: 1.2rem;
      border-radius: 0.5rem !important;
      background-color: #3f63c7 !important;
      text-transform: uppercase;
      padding: 0.4rem 2rem !important;
      margin: 2rem 0 0 0;
      color: white;
      height: auto;
      width: 49%;
      font-family: Roboto;
    }
  }
}

.missing-info {
  display: flex;
  flex-direction: column;
  background-color: white !important;
  border: 2px solid #9a9ae0;
  margin: auto;
  width: 100%;
  font-family: "Roboto";
  font-size: 1.2rem;
  border-radius: 0.5rem;
  padding: 2rem 5rem;
  margin-top: 2rem;
  text-align: center;
  img {
    width: auto;
    margin-bottom: 2rem;
    object-fit: contain;
  }
}

.checkboxs {
  text-align: left;
  padding-left: 1rem;
  padding-top: 1rem;
  padding-bottom: 0.1rem;
  margin-bottom: 5px;
}