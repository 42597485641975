.equipments-container {
  background-color: #f7f7f7;
  position: relative;
  padding: 2rem 4rem;
  margin-top: 55px;
  z-index: 0;
  overflow: hidden;
  .wave {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 83%;
    width: 100%;
    z-index: -1;
  }
  .background-top {
    position: absolute;
    right: 17%;
    top: 1%;
    height: auto;
    width: 40%;
    z-index: -1;
  }
  .illustration {
    grid-column: 3/4;
    grid-row: 1/2;
    object-fit: cover;
    position: absolute;
    right: 0;
    top: 55px;
    height: auto;
    bottom: 0;
    width: 34%;
    z-index: -1;
  }
}

.equipments-wrapper {
  min-height: 84vh;
  margin: auto;
  display: flex;
  text-align: left;
  flex-direction: column;
  justify-content: flex-start;
  font-family: "Roboto";

  h1 {
    font-family: "DM Sans";
    font-size: 3rem;
    justify-self: flex-end;
    font-weight: bold;
    text-align: left;
    margin: 2rem 0rem;
  }

  .ade-header {
    h1 {
      font-family: "DM Sans";
      font-size: 2.5rem;
      justify-self: flex-end;
      font-weight: bold;
      text-align: left;
      color: rgb(63, 99, 199);
      margin: 2rem 0rem;
    }
  }

  .form-cont {
    .form-control {
      background-color: transparent;
      border: 1px solid #a7a7a7;
    }

    .btn-gris {
      grid-row: 4/5;
      font-size: 1.2rem !important;
      border-radius: 0.5rem !important;
      background-color: #999999 !important;
      text-transform: uppercase;
      padding: 0.4rem 2rem !important;
      margin: 1rem 0.5rem 0 0;
      margin-left: 0 !important;
      color: white;
      height: auto;
      width: 49%;
      font-family: Roboto;
    }

    .btn-celeste {
      grid-row: 4/5;
      font-size: 1.2rem;
      border-radius: 0.5rem !important;
      background-color: #3f63c7 !important;
      text-transform: uppercase;
      padding: 0.4rem 2rem !important;
      margin: 1rem 0 0 0;
      color: white;
      height: auto;
      width: 49%;
      font-family: Roboto;
    }
  }

  .header {
    text-align: left;
    width: 100%;
    color: rgb(63, 99, 199);
    display: flex;

    h1 {
      font-size: 1.65rem;
      padding: 0rem 10px 1rem;
      margin: 0;
      display: inline-block;
      font-weight: 900;
      font-family: "DM Sans", sans-serif;
      text-align: left;
    }
  }

  .header-equipments {
    display: grid;
    grid-template-columns: 0% 1fr 1fr 5%;
    margin-bottom: 3rem;

    .text-header {
      grid-column: 2/3;
      text-align: left;

      h1 {
        font-family: "Roboto";
        font-size: 3.1rem;
        font-weight: bold;
        text-align: left;
        margin: 1.5rem 0;
        color: black;
      }

      h2 {
        font-family: "Roboto";
        font-size: 1.5rem;
        text-align: left !important;
        font-weight: 100;
        color: gray;

        .rotate-90 {
          -webkit-transform: rotate(90deg);
          -moz-transform: rotate(90deg);
          -ms-transform: rotate(90deg);
          -o-transform: rotate(90deg);
          transform: rotate(90deg);
        }
      }
    }

    .button-header {
      grid-column: 3/4;
      align-self: center;

      .btn-celeste {
        padding: 0.5rem 4rem;
        font-size: 1.3rem;
        float: right;
        background-color: #3f63c7;
        color: white;
      }
    }
  }
}

@media (max-width: 768px) {
  .equipments-container {
    padding: 2rem;
    .background-top {
      display: none;
    }
  }

  .equipments-wrapper {
    .header-equipments {
      display: flex;
      flex-direction: column;
      .text-header {
        h1 {
          font-size: 2.5rem;
        }
      }
      .button-header {
        width: 100%;

        .btn-celeste {
          width: 100%;
        }
      }
    }
  }
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef !important;
  opacity: 1;
}
