.equipment-container{
    background-color: #f7f7f7;
    position: relative;
    padding: 2rem 4rem;
    margin-top: 55px; 
    min-height: 92.5vh;
    overflow-x: hidden;
    z-index: 0; 
    
    .wave {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 83%;
        width: 100%;
        z-index: -1;
    }
}

.equipment-status{
  
    .es-header{
        h1 {
            font-weight: 600 !important;
            font-size: 3.2rem !important;
            color: #19264a !important;
            font-family: "DM Sans";
            text-align: left;
        }

        h2 {
            font-size: 1.4rem !important;
            color: #5f5f5f !important;
            font-family: "DM Sans";
            text-align: left;
        }
    }

    .card-equipment{
        cursor: pointer;
        margin-top: 2rem;
        background-color: white;
        border: 1px solid black;
        padding: 0rem 0 1rem 0;

        h3{
            font-family: Roboto;
            font-size: 1.4rem !important;
            text-transform: uppercase;
        }

        h4{
            font-family: "DM Sans" !important;
            font-size: 3rem !important;
            text-transform: uppercase;
        }

        p{
            font-family: "DM Sans" !important;
            font-size: 1.2rem !important;
            text-transform: uppercase;
        }
    }
}