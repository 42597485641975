@import url("https://fonts.googleapis.com/css?family=Open+Sans&display=swap");

/* ==============
  Account Pages
===================*/

.accountbg {
  background: url("../../../assets/images/bg3.jpg");
  position: absolute;
  background-size: cover;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 5;
}



.wrapper-page {
    margin:auto;
    width: 27rem;
    height: 29rem;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    text-align: left;
}

.wrapper-page .logo-admin {
  img{
      height: 2.8rem; 
  }

}



.p-3 {
    padding-top: 0 !important; 
    h4 {
        font-family: "DM Sans" !important;
        font-weight: 600;
        color: rgb(63, 99, 199);
        font-size: 1.4rem !important;
    }

     p.text-center{
        text-align: center !important;
    }
}

.wrapper-page > .card {
  border-radius: 0.5rem;
  z-index: 6;
}


.button-login {
    border-radius: 0.5rem !important;
    padding: 0.5rem !important;
    width: 11rem !important;
    margin-top: 1rem !important;
    float: right !important;
    background-color: rgb(63, 99, 199) !important;
    color: white !important;
    outline: none !important;
}
.icon {
  font-size: 1.15vw !important;
  vertical-align: middle;
}

.hidden{
    display:none !important; 
}

.error-msg{
    color: red;
    font-size: 15px; 
}

.error {
  color: red;
  font-size: 12px;
  margin-left: 2px;
}

/* ==============
  Form-elements
===================*/
label {
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  font-size: 14px !important;
}

input[type="color"] {
  min-height: 38px;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #acacac !important;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #acacac !important;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #acacac !important;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #acacac !important;
}

.form-control {
  font-family: "Source Sans Pro", sans-serif;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.form-control:focus {
  border-color: #a2a2a2;
}

.custom-control-input:checked ~ .custom-control-indicator {
  background-color: #67a8e4;
}

.custom-control-input:focus ~ .custom-control-indicator {
  -webkit-box-shadow: 0 0 0 1px #ffffff, 0 0 0 3px #67a8e4;
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 3px #67a8e4;
}

.has-success .form-control {
  border-color: #67a8e4;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.has-warning .form-control {
  border-color: #ffbb44;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.has-error .form-control {
  border-color: #ea553d;
  -webkit-box-shadow: none;
  box-shadow: none;
}

/* ==============
  Form-Validation
===================*/
.error {
  color: #ea553d;
}

.parsley-error {
  border-color: #ea553d;
}

.parsley-errors-list {
  display: none;
  margin: 0;
  padding: 0;
}

.parsley-errors-list.filled {
  display: block;
}

.parsley-errors-list > li {
  font-size: 12px;
  list-style: none;
  color: #ea553d;
  margin-top: 5px;
}


@media (max-width: 768px){
  .card-body{
    .p-3{
      p{
        font-size: 1.2rem; 
      }
    }
  }

  .wrapper-page{
    .logo-admin{
      img{
        height: 3.3rem;
      }
    }
  }

  .button-login{
    font-size: 1.1rem !important;
  }

  .form-control{
    font-size: 1.2rem !important;
  }
}
