.cropped-image {
  height: 400px;
  width: auto;
}

.cropped-image-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

._coverImage-holder {
  padding: 25px 40px;
  background-color: black;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
}

.container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.crop-container {
  height: 400px;
  width: 400px;
}

.controls {
  display: flex;
  flex-direction: column;
  width: 85%;
  // position: absolute;
  // bottom: -15px;
}
