@import url("https://fonts.googleapis.com/css?family=Roboto:400,500|Source+Sans+Pro:400,600,700");
/* ==============
  General
===================*/
body {
    background: #f5f5f5;
    font-family: "Source Sans Pro", sans-serif;
    margin: 0;
    overflow-x: hidden;
}
.btn-primary {
    color: #fff;
}
html {
    overflow-x: hidden;
    position: relative;
    min-height: 100%;
    background: #f5f5f5;
}

* {
    outline: none !important;
}

a:hover {
    outline: 0;
    text-decoration: none;
}

a:active {
    outline: 0;
    text-decoration: none;
}

a:focus {
    outline: 0;
    text-decoration: none;
}

code {
    background-color: #f7f7f9;
    padding: .2rem .4rem;
    border-radius: .25rem;
}


/* ==============
  Helper Classes
===================*/
.header-title {
    font-size: 16px;
    margin-bottom: 5px;
}

.p-0 {
    padding: 0;
}

.p-t-10 {
    padding-top: 10px;
}

.p-b-10 {
    padding-bottom: 10px;
}

.m-0 {
    margin: 0;
}

.m-r-5 {
    margin-right: 5px;
}

.m-r-10 {
    margin-right: 10px;
}

.m-r-15 {
    margin-right: 15px;
}

.m-l-10 {
    margin-left: 10px;
}

.m-l-15 {
    margin-left: 15px;
}

.m-t-5 {
    margin-top: 5px;
}

.m-t-10 {
    margin-top: 10px;
}

.m-t-15 {
    margin-top: 15px;
}

.m-t-20 {
    margin-top: 20px;
}

.m-t-30 {
    margin-top: 30px;
}

.m-t-40 {
    margin-top: 40px;
}

.m-b-5 {
    margin-bottom: 5px;
}

.m-b-10 {
    margin-bottom: 10px;
}

.m-b-15 {
    margin-bottom: 15px;
}

.m-b-20 {
    margin-bottom: 20px;
}

.m-b-30 {
    margin-bottom: 30px;
}


.font-secondary {
    font-family: "Roboto", sans-serif;
}

.no-border {
    border: none;
}

.bx-shadow {
    -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}

.thumb-sm {
    height: 32px;
    width: 32px;
}

.thumb-md {
    height: 48px;
    width: 48px;
}

.thumb-lg {
    height: 88px;
    width: 88px;
}

.text-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

label {
    font-size: 0.9rem !important;
}

.dropdown-menu {
    font-size: 1vw;
}



@media (min-width: 1600px) {
    html {
        font-size: 120%;
    }
}

@media (min-width: 2000px) {
    html {
        font-size: 150%;
    }
}

@media (max-width: 768px) {
    html {
        font-size: 75%;
    }

    .col-12 {
        padding: 0 !important;
    }
}
