.fondo {
    display: flex;
    position: absolute;
    width: 100vw;
    height: 100%;
    min-height: 100vh;
    margin: auto;
    text-align: center;
    align-items: center;
    background: rgba(0,0,0,0.5);
    z-index: 100;
    top: 0;
    left: 0;
}

.fondo-modal {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    margin: auto;
    text-align: center;
    align-items: center;
    background: rgb(223 223 223 / 50%);
    z-index: 100;
    top: 0;
    left: 0;
}

.hidden{
    display:none !important; 
}

.spinner-custom{
    margin:auto;
}