.register-container {
  background-color: #f7f7f7;
  position: relative;
  overflow: hidden;
  z-index: 0;
}

.register-wrapper {
  display: grid;
  min-height: 100vh;

  .register {
    margin: 6rem 4rem;
    text-align: left;
    h1 {
      font-family: "DM Sans";
      font-size: 3rem;
      justify-self: flex-end;
      margin-bottom: 2rem;
      align-self: center;
      font-weight: bold;
      text-align: left;
    }

    .header {
      text-align: left;
      width: 100%;
      color: rgb(63, 99, 199);
      display: flex;

      h1 {
        font-size: 1.65rem;
        padding: 0;
        margin: 0;
        display: inline-block;
        font-weight: 900;
        font-family: "DM Sans", sans-serif;
        text-align: left;
        margin-bottom: 4rem;
      }
    }

    .actual-payment {
      grid-row: 3/4;
    }

    .form-control-select {
      width: 100%;
    }
    .MuiFormLabel-root {
      color: black;
      margin-left: 1rem;
    }

    .form-register {
      grid-row: 3/4;
      align-content: flex-end;

      .form-control {
        background-color: transparent;
        border: none;
        border-bottom: 1px solid gray;
        border-radius: 0rem;
      }

      .custom-select {
        background-color: transparent !important;
        border: none;
        border-bottom: 1px solid gray;
        border-radius: 0;
      }

      .form-control:active,
      .form-control:focus {
        border-bottom: 1px solid blue;
      }
    }

    .btn-gris {
      grid-row: 4/5;
      font-size: 1.2rem !important;
      border-radius: 0.5rem !important;
      background-color: #999999 !important;
      text-transform: uppercase;
      padding: 0.4rem 2rem !important;
      margin: 3rem 0.5rem 0 0;
      color: white;
      height: auto;
      font-family: Roboto;
    }

    .btn-celeste {
      grid-row: 4/5;
      font-size: 1.2rem;
      border-radius: 0.5rem !important;
      background-color: #3f63c7 !important;
      text-transform: uppercase;
      padding: 0.4rem 2rem !important;
      margin: 3rem 0 0 0;
      color: white;
      height: auto;
      font-family: Roboto;
    }
  }
}
