.technicianhome-container {
    background-color: #f7f7f7;
    min-height: 90vh;
    overflow-y: hidden;
    margin-top: 55px;
    overflow: hidden;

    .home-wrapper {
        display: grid;
        min-height: 90vh;
        grid-template-columns: 2% minmax(5rem, 2fr) minmax(30rem, 1fr) 5%;
        grid-template-rows: 1fr;
        overflow: hidden;

        .bienvenido {
            grid-column: 3/4;
            grid-row: 1/2;
            align-self: center;
            padding-bottom: 30%;

            h1 {
                font-weight: 600 !important;
                font-size: 4.5rem !important;
                color: #19264a !important;
                font-family: "DM Sans";
                text-align: left;
            }

            .btn-celeste {
                font-size: 1.2rem;
                border-radius: 0.5rem !important;
                background-color: #3f63c7 !important;
                text-transform: uppercase;
                padding: 0.4rem 2rem !important;
                margin: 1rem 0;
                color: white;
                width: 100%;
                font-family: Roboto;
            }

            h4 {
                font-size: 1.4rem !important;
                color: #5f5f5f !important;
                font-family: "DM Sans";
                text-align: left;
            }
        }

        img {
            grid-column: 2/3;
            grid-row: 1/2;
            object-fit: cover;
            height: 75%;
            padding-top: 1%;
            align-self: center;
            justify-self: center;
        }
    }
}

@media (max-width: 768px) {
    .technicianhome-container {
    .home-wrapper {
        display: flex;
        min-height: 90vh;
        position: relative;
        overflow: hidden;

        .bienvenido {
            position: relative;
            align-self: flex-start;
            padding-top: 30%;
            margin:1rem;
            padding-bottom: 0%;

            h1 {
                font-weight: 600 !important;
                font-size: 4rem !important;
                color: #19264a !important;
                font-family: "DM Sans";
                text-align: left;
            }

            .btn-celeste {
                font-size: 1.2rem;
                border-radius: 0.5rem !important;
                background-color: #3f63c7 !important;
                text-transform: uppercase;
                padding: 0.4rem 1rem !important;
                margin: 1rem 0;
                color: white;
                width: 100%;
                font-family: Roboto;
            }

            h4 {
                font-size: 1.2rem !important;
                color: #5f5f5f !important;
                font-family: "DM Sans";
                text-align: left;
            }
        }

        img {
            position: absolute;
            object-fit: cover;
            height: 100%;
            top:0;
            opacity: 0.5;
            left:0;
        }
    }
}
}
