.money-card {
    display: flex;
    height: 100%;
    justify-content: space-around;

    img {
        max-width: 50%;
        max-height: 70%;
        align-self: center;
    }



    .money-description {
        display: flex;
        flex-direction: column;
        justify-content: center;

        h5 {
            text-align: left;
            color: black !important;
            font-weight: 600;
            font-size: 2.2rem;
            font-family: "DM Sans";
        }

        .desc {
            p {
                color: #575757;
                text-align: left !important;
                margin-top: 0 !important;
                margin-bottom: 0 !important;
                font-family: "DM Sans" !important;
            }
        }
    }
}

.timer-card {
    display: flex;
    height: 100%;
    justify-content: space-around;

    img.time {
        width: 34%;
        height: 85%;
        margin-top: 0.5rem;
    }

    h5 {
        text-align: center;
        color: black !important;
        font-weight: 600;
        text-transform: none !important;
        font-size: 3rem;
        font-family: "DM Sans";
    }
}

.card {
    p {
        text-align: left !important;
        margin-bottom: 0 !important;
        margin-top: 0.5rem !important;
    }
}

.header {
    h2 {
        font-size: 1.4rem;
        margin: 0;
        display: inline-block;
        font-weight: 900;
        font-family: "DM Sans", sans-serif;
        text-align: left;
    }

    .filter-icon {
        margin: 0 0.5rem;
    }
}

.header-record {
    text-align: left;
    h1 {
        font-size: 2rem !important;
        margin: 0;
        display: inline-block;
        font-weight: 900;
        color: #3f63c7 !important;
        font-family: "DM Sans", sans-serif;
        text-align: left !important;
    }

    p{
        font-size: 1.2rem;
        text-align: left !important;
        font-weight: 500;
        color: #252525 !important;
        font-family: "DM Sans", sans-serif;
    }

    .filter-icon {
        margin: 0 0.5rem;
    }
}

.statistics {
    #form {
        width: 100%;
        text-align: left !important;
        padding: 1rem;
    }


    .form-label {
        text-align: left !important;
    }

    .btn-celeste {
        float: right;
        background-color: #3f63c7;
        color: white;
        padding: 0.3rem 3rem;
        font-size: 1.1rem;
    }

    .btn-celeste:hover {
        background-color: #3f63c7;
    }
}

@media (max-width: 768px){
    .statistics{
        .cards{
            flex-direction: column-reverse;
            .card{
                margin: 1rem 0;
            }
        }
    }
}