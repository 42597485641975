.rt-container {
    background-color: #f7f7f7;
    position: relative;
    padding: 2rem 4rem;
    margin-top: 55px;
    min-height: 92.5vh;
    overflow-x: hidden;
    z-index: 0;

    .wave {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 75%;
        width: 100%;
        z-index: -1;
    }

    .waves-bottom {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
    }
}

.review-technician {
    .step-description {
        text-align: left;
        margin-top: 1rem;
        p {
            color: #3f63c7;
            font-weight: 600;
            font-size: 1.5rem;
            text-align: left !important;
            font-family: "DM Sans" !important;
        }
    }

    .mapa{
        margin-top: 2rem;
    }

    .header-rtp {
        h1 {
            font-weight: 600 !important;
            font-size: 2.2rem !important;
            color: #2c2c2c !important;
            font-family: "DM Sans";
            margin: 2rem 0 0rem 0;
            text-align: left;
        }
        h2 {
            font-weight: 600 !important;
            font-size: 2.2rem !important;
            color: #3f63c7 !important;
            font-family: "DM Sans";
            margin: 2rem 0 0rem 0;
            text-align: left;
        }
    }

    .header-button {
        float: right;
    }

    .header-rt {
        z-index: 2;
        position: relative;
        h1 {
            font-weight: 600 !important;
            font-size: 3.2rem !important;
            color: #19264a !important;
            font-family: "DM Sans";
            text-align: left;
        }

        h3 {
            font-size: 1.4rem !important;
            color: #8a8a8a !important;
            font-weight: 500 !important;
            margin-top: 0 !important;
            font-family: "DM Sans";
            text-align: left;
        }
    }

    .btn-celeste {
        position: relative;
        z-index: 2;
        padding: 0.5rem 2rem;
        font-size: 1.3rem;
        border-radius: 0.25rem !important;
        float: right;
        background-color: #3f63c7 !important;
        color: white;
        font-family: Roboto;
        font-weight: 500;
        text-transform: uppercase;
    }
    .btn-celeste :hover {
        background-color: #3f63c7 !important;
        color: white;
        text-decoration: none;
        text-transform: uppercase;
    }

    .card-review {
        z-index: 2;
        position: relative;
        margin-top: 2rem;
        padding: 1rem;
        border-radius: 0.25rem;
        border: 1px solid black;
        cursor: pointer;
        .icon-header {
            font-size: 3.5rem;
        }

        h4 {
            font-size: 1.3rem !important;
            font-weight: bold;
            margin-bottom: 1rem;
        }

        p {
            text-align: left !important;
            margin-bottom: 0.5rem;
            .description-cr {
                font-weight: 500;
            }
        }
    }

    .description-equipment {
        margin: auto;
        background-color: #495575;
        border-radius: 0.5rem;
        border: 1px solid #495575;
        padding: 2rem;
        margin-top: 2rem;
        color: #ebebeb;

        .icon-de {
            font-size: 3.5rem;
        }

        h2 {
            font-size: 1.3rem;
            font-family: Roboto;
            font-weight: bold;
            margin: 0 !important;
        }

        h3 {
            margin: 0.3rem 0 2rem 0 !important;
            font-weight: 400 !important;
            font-size: 1.1rem !important;
        }

        p {
            font-family: "Roboto";
            font-size: 1rem !important;
            text-align: left !important;
            margin-bottom: 0.5rem;

            .desc {
                font-weight: 500;
            }
        }
    }
    .btn-container {
        width: 100%;
        margin-top: 2rem;
        .btn-celeste {
            padding: 0.5rem 2rem;
            font-size: 1.1rem;
            border-radius: 0.25rem !important;
            float: right;
            background-color: #3f63c7;
            color: white;
            font-family: "Roboto";
            text-transform: uppercase;
            float: right;
        }
        .btn-gris {
            padding: 0.5rem 2rem !important;
            font-size: 1.1rem !important;
            border-radius: 0.25rem !important;
            font-family: "Roboto";
            margin-right: 1rem;
            text-transform: uppercase;
            float: right;
        }
    }

    .checklist {
        display: flex;
        flex-direction: column;
        margin-top: 2rem;
        .checklist-item {
            display: flex;
            justify-content: space-between;

            .dropdown-toggle::after {
                color: #3f63c7;
            }
        }
    }

    .ok-description {
        p {
            text-align: left !important;
        }
    }

    .info-icon{
        font-size: 1.8rem !important;
    }

    .retirar-equipo {
        display: block;
        flex-direction: column;
        text-align: left !important;

        p {
            text-align: left !important;
            margin-bottom: 0;
            font-weight: 500;
            font-size: 1.1rem;
            margin-top: 1rem;
        }

        .btn-rojo {
            padding: 0.5rem 2rem !important;
            font-size: 1.1rem !important;
            border-radius: 0.25rem !important;
            font-family: "Roboto";
            margin: 1rem 0;
        }
    }
}

@media (max-width: 768px) {
    .review {
        padding: 2rem 3rem;
    }

    .review-technician {
        .col-sm-12 {
            padding: 0 !important;
        }
        .description-equipment{
            p{
                font-size: 1.2rem !important;
            }

            h3{
                font-size: 1.3rem !important;
            }

            h2{
                font-size: 1.7rem!important;
            }
        }
        .header-rtp{
            h1{
                font-size: 2.5rem !important;
            }
            h2{
                margin: 0;
                text-align: center;
            }
        }

        .mapa{
            padding: 0;
            height: 22rem;
        }

        .card-review{
            p{
                font-size: 1.2rem;
            }

            h4{
                font-size: 1.4rem !important;
            }
        }

        .step-description {
            p {
                font-size: 1.8rem;
            }
        }

        .header-rt {
            z-index: 2;
            position: relative;
            h1 {
                font-size: 2.2rem !important;
            }

            h3 {
                font-size: 1.2rem !important;
            }
            
        }
        
        .header-button {
            float: none;
            .btn-celeste {
                float: none;
                width: 100%;
            }
        }

        .info-icon {
            font-size: 2.5rem !important;
        }

        .MuiTypography-body1 {
            font-size: 1.5rem;
            text-align: left;
        }
        .dropdown-toggle::after {
            vertical-align: sub;
        }
        .btn-container {
            .btn-celeste,
            .btn-gris {
                width: 45%;
                font-size: 1.4rem !important;
                padding: 0.5rem 2rem !important;
            }
        }
        .retirar-equipo {
            display: flex;
            text-align: center;
            margin: 2rem 0;
            p {
                text-align: center !important;
                font-size: 1.2rem;
            }
            .btn-rojo {
                margin: 1rem auto;
                font-size: 1.4rem !important;
            }
        }
    }

    .rt-container{
        padding:2.5rem;
    }

    .statistics-container{
        padding: 2rem;

        .statistics{
            .header-user{
                display:flex;
                flex-direction: column;

                .text-header{
                    h1{
                        font-size: 2.5rem;
                    }
                }
                .button-header{
                    width: 100%;
                    max-width: 100%;

                    .btn-gris{
                        width:100%
                    }
                }
            }

          
        }
    }
}
