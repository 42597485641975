.btn-gris {
    border-radius: 0.25rem !important;
    background-color: #9c9c9c !important;
    text-transform: uppercase;
    padding: 0.4rem 1.5rem !important;
    margin-left: 0.5rem !important;
    max-width: 100%;
    font-family: Roboto;
    font-size: 1vw !important;
    color: white !important; 
}

.btn-rojo {
    border-radius: 0.25rem !important;
    background-color: #a91313f2 !important;
    text-transform: uppercase;
    padding: 0.4rem 1.65vw !important;
    margin: 0.5rem;
    font-family: Roboto;
    font-size: 1vw !important;
    color: white !important; 

}

.btn-azul {
    border-radius: 0.25rem !important;
    background-color: #4e74c7 !important;
    text-transform: uppercase;
    padding: 0.4rem 1.5rem !important;
    margin: 0.5rem;
    font-family: Roboto;
    font-size: 1vw !important;
    color: white !important; 

}

.addNew{
    padding: 0.4rem 0.7rem !important;
}

.btn-verde {
    border-radius: 0.25rem !important;
    color: white !important;
    background-color: #669e53 !important;
    text-transform: uppercase;
    padding: 0.4rem 1.65vw !important;
    margin: 0.5rem;
    font-family: Roboto;
    font-size: 1vw !important;

}

.title {
    font-family: "Roboto", sans-serif;
    font-weight: 500 !important;
    font-size: 1.1vw !important;
    color: #5f5f5f;
}

h3 {
    font-family: "Roboto", sans-serif !important;
    font-weight: 700 !important;
    font-size: 1vw !important;
    text-align: center;
    margin: 1rem 0 2rem 0 !important;
}



.motivo {
    font-size: 1vw;
    font-family: "DM Sans", sans-serif !important;
    font-weight: bold;
    margin: 7px 5px 7px 23px !important;

    .icons {
        vertical-align: middle;
    }
}

.descripcion {
    color: grey;
    font-size: 1vw;
    font-family: "DM Sans", sans-serif !important;
    margin: 7px 5px !important;
}

.confirmacion-exitosa {
    text-align: center;
    margin: 1rem 0 !important;

    .icons {
        vertical-align: middle;
        color: green;
    }

    img {
        width: 90px;
        height: 90px;
        margin-bottom: 1rem;
    }

    p {
        font-weight: bold;
        font-family: "DM Sans", sans-serif !important;
        font-size: 1vw;
    }
}


.confirmation {
    text-align: center;
    margin-top: 1rem !important;

    img {
        width: 80px;
        height: 80px;
        margin-bottom: 1rem;
    }

    p {
        font-weight: bold;
        font-family: "DM Sans", sans-serif !important;
        font-size: 1.2vw;
    }
}

h4 {
    text-align: center;
    font-size: 1.2vw !important;
    font-family: "Roboto", sans-serif !important;
}

p {
    text-align: center !important;
    font-family: "Roboto", sans-serif !important;
}



.border-bottom{
    border-bottom: 1px solid gray;
}

.modal-body{
    p{
        font-size:1vw; 
    }

    .review-details{
             margin: 0rem 1rem;

        .header-rd{
            border-bottom: 2px solid #5876c9;
            margin-bottom: 1.5rem;
            h3{
                text-align: left;
                margin-bottom: 0.5rem !important;
            }
            
        }
        .body-rd{
            p{
                text-align: left!important;
            }
        }
    }
}

@media (max-width: 900px) {
    .btn-gris {
        padding: 0.4rem 1rem !important;
        font-size: 1rem !important;
    }

    .modal-title{
        font-size:1.3rem !important;
        }
    .btn-rojo {
        padding: 0.4rem 1rem!important;
        font-size: 1rem !important;
    }
    
    .btn-azul {
        padding: 0.4rem 1rem !important;
        font-size: 1rem!important;
    }
    
    .btn-verde {
        padding: 0.4rem 1rem !important;
        font-size: 1.15rem !important;
    }

    .btn-celeste {
        padding: 0.4rem 1rem !important;
        font-size: 1rem !important;
    }

    .confirmacion-exitosa{
        img{
            height: 5rem;
            width:5rem;
        }

        p {
            font-size: 1.25rem;
        }
    }

    .modal-body .review-details .header-rd h3{
        font-size: 1.2rem !important;
    }

    .modal-body {
        h4{
            font-size: 1.2rem !important;
        }
    } 

    .modal-body p {
        font-size: 1.2rem; 
    }
    
}


@media (max-width: 764px) {
    .btn-gris {
        padding: 0.4rem 1rem !important;
        font-size: 1.15rem !important;
    }

    .modal-title{
        font-size:1.3rem !important;
        }
    .btn-rojo {
        padding: 0.4rem 1rem!important;
        font-size: 1.15rem !important;
    }
    
    .btn-azul {
        padding: 0.4rem 1rem !important;
        font-size: 1.15rem!important;
    }
    
    .btn-verde {
        padding: 0.4rem 1rem !important;
        font-size: 1.15rem !important;
    }

    .btn-celeste {
        padding: 0.4rem 1rem !important;
        font-size: 1.15rem !important;
    }

    .confirmacion-exitosa{
        img{
            height: 5rem;
            width:5rem;
        }

        p {
            font-size: 1.25rem;
        }
    }

    .modal-body .review-details .header-rd h3{
        font-size: 1.4rem !important;
    }

    .modal-body p {
        font-size: 1.2rem; 
    }
    
}
