.supervisor-container {
    background-color: #f7f7f7;
    min-height: 90vh;
    overflow-y: hidden;
    margin-top: 55px;
    overflow: hidden;

    .homesuper-wrapper {
        display: grid;
        min-height: 90vh;
        grid-template-columns: 10% minmax(30rem, 1fr) minmax(5rem, 2fr) 2%;
        grid-template-rows: 1fr;
        overflow: hidden;


        .bienvenido {
            grid-column: 2/3;
            grid-row: 1/2;
            align-self: center;
            padding-bottom: 30%;


            h1 {
                font-weight: 600 !important;
                font-size: 4.5rem !important;
                color: #19264a !important;
                font-family: "DM Sans";
                text-align: left;
            }

  

            .videos-amount{
                font-size: 1.5rem;
                color: #3f63c7;
                text-align: left;
                font-weight: 600;
                font-family: "Roboto", sans-serif !important;
            }

            .btn-celeste {
                font-size: 1.2rem;
                border-radius: 0.5rem !important;
                background-color: #3f63c7 !important;
                text-transform: uppercase;
                padding: 0.4rem 2rem !important;
                margin: 1rem 0;
                color: white;
                width: 100%;
                font-family: Roboto;
            }

            h4 {
                font-size: 1.4rem !important;
                color: #5f5f5f !important;
                font-family: "DM Sans";
                text-align: left;
            }
        }

        img {
            grid-column: 3/4;
            grid-row: 1/2;
            object-fit: cover;
            height: 75%;
            padding-top: 0%;
            align-self: center;
            justify-self: center;
        }
    }
}

@media (max-width: 768px){
    .supervisor-container {
       

        .homesuper-wrapper {
            display: flex;
           position:relative;
            overflow: hidden;
    
            .bienvenido {
               
                align-self: center;
                padding-bottom: 30%;
                margin: 1rem;
                z-index:3;
                h1 {
                    font-size: 4rem !important;
                    
                }
    
                
    
                h4 {
                    font-size: 1.2rem !important;
                    color: #5f5f5f !important;
                    font-family: "DM Sans";
                    text-align: left;
                }
            }
    
            img {
               position: absolute;
               top: 0;
                left:0;
                object-fit: cover;
                height: 100%;
                padding-top: 3%;
                opacity: 0.6;
                z-index:1;
                align-self: center;
                justify-self: center;
            }
        }
    }
    
}
