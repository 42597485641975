.equipment-container {
  .equipment-status {
    .es-header {
      margin: 1.5rem 0;
      h1 {
        font-weight: 700 !important;
        font-size: 2.3rem !important;
        color: #19264a !important;
        font-family: "DM Sans";
        text-align: left;
      }

      h2 {
        font-size: 1.2rem !important;
        color: #5f5f5f !important;
        font-family: "DM Sans";
        text-align: left;
      }
    }
  }
  .card {
    margin: 0.7rem;
    border-radius: 0.5rem !important;

    border: 0px solid #999999 !important;
    .card-header {
      background-color: white;
      text-align: left;
      cursor: pointer;
      box-shadow: -13px -1px 20px 2px #cecccc;
      .accordion-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .tittle-accordion {
          font-size: 1.2rem;
          padding: 0.7rem 2rem;
          font-weight: 600;
        }
        .icon-header {
          width: 35px;
          height: 35px;
          background-color: #19264a;
          border-radius: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
        }
      }
    }
    .card-body {
      padding: 3rem 1.5rem 3rem 2rem;
      border-radius: 0.5rem;

      .body-header {
        display: flex;
        justify-content: flex-end;
        .search-input {
          display: flex;
          place-items: flex-end;
          .material-icons {
            color: #5f5f5f;
            margin-right: 0.5rem;
          }
        }
      }
      .btn-icon {
        background-color: #3f63c7;
        border-radius: 60px;
        color: white;
        border: none;
        align-items: center;
        justify-content: center;
        display: flex;
        padding: 0.75rem;
        margin-left: 1rem;
      }
      .analizadores {
        display: flex;
        .analizador {
          background-color: #b5c7fa;
          padding: 0.75rem 2rem;
          margin: 0.5rem;
          border-radius: 0.5rem;
          font-weight: 600;
        }
      }
    }
  }
}
