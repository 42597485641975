.toolbar {
  z-index: 4;
  position: fixed;
  width: 100%;
  background: rgb(63, 99, 199);
  height: 55px;
  top: 0;
  left: 0;
  box-shadow: 0 2px 3px -2px rgba(0, 0, 0, 0.15);
}

.color {
  color: black;
}

.icons {
  vertical-align: middle;
  font-size: 1.4rem !important;
  margin-right: 0.25rem;
}

.toolbar_navigation {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 1.5rem;
}

.toolbar_logo {
  margin-left: 1rem;
}

.logo-h {
  height: 40px;
  vertical-align: text-top;
}

.toolbar_logo:hover {
  cursor: pointer;
}

.toolbar_navigation-items a {
  color: #fff;
  text-decoration: none;
  font-size: 1.1rem;
}

.toolbar_navigation-items ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.toolbar_navigation-items li {
  padding: 0.5rem;
  margin: auto;
  color: white;
  font-size: 1.2rem !important;
  font-family: "DM Sans";
}

.spacer {
  flex: 1;
}

.toolbar_navigation-items a:hover,
.toolbar_navigation-items a:active {
  color: white;
}

.dropdown-item:active {
  background-color: #f7f7f7 !important;
}

@media (max-width: 768px) {
  .toolbar {
    .toolbar_navigation-items {
      display: none;
    }
  }
}

.tooltipg {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltipg.refresh {
  border-radius: 50%;
  border: 2.5px solid white;
  padding: 0.2rem;
  display: flex;
  margin-left: 1.5rem;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
}

.tooltipg .tooltiptextg {
  visibility: hidden;
  width: 120px;
  font-size: 0.9rem;
  font-family: "DM sans";
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -60px;
}

.tooltipg:hover .tooltiptextg {
  visibility: visible;
}
