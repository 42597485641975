.statistics-container{
    .statistics{
        
.header-repair {
    display: grid;
    grid-template-columns: 0% 1fr 1fr 5%;
    margin-bottom: 3rem;

    .text-header {
        grid-column: 2/3;
        text-align: left;

        h1 {
            font-family: "Roboto";
            font-size: 3.1rem;
            font-weight: bold;
            text-align: left;
            margin: 1.5rem 0;
            color: #484848;
        }

        h2 {
            font-family: "Roboto";
            font-size: 1.5rem;
            text-align: left !important;
            font-weight: 100;
            color: gray;

            .rotate-90 {
                -webkit-transform: rotate(90deg);
                -moz-transform: rotate(90deg);
                -ms-transform: rotate(90deg);
                -o-transform: rotate(90deg);
                transform: rotate(90deg);
            }
        }
    }

    .button-header {
        grid-column: 3/4;
        align-self: center;

        .btn-celeste {
            padding: 0.5rem 2rem;
            font-size: 1.3rem;
        }

        .btn-gris {
            margin-top:0;
            color: white; 
            padding: 0.5rem 5rem !important;
            font-size: 1.3rem !important;
            float:right; 
        }
    }
}

    }
}