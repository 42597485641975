@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");

.side-drawer {
    height: 100%;
    background-color: rgb(63, 99, 199);
    box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 65%;
    max-width: 250px;
    z-index: 200;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;

    .mobile-only {
        display: none;
    }

    .toolbar_navigation-items a {
        color: #fff;
        text-decoration: none;
        font-size: 1.1rem;
    }

    .toolbar_navigation-items ul {
        list-style: none;
        margin: 0;
        padding: 1rem 0 0 0;
        display: flex;

        .nav-link {
            padding: 0;
        }
    }

    .toolbar_navigation-items li {
        padding: 0rem;
        margin: auto;
        color: white;
        font-size: 1.2rem !important;
        font-family: "DM Sans";
    }

    .toolbar_navigation-items li:active {
        background-color: #2f57c5;
    }
}

.side-drawer ul {
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-left: 0;
    margin-top: 30px;
}

.margin-top {
    margin-top: 15px;
}

.side-drawer.open {
    transform: translateX(0);
}

.side-drawer li {
    padding: 0.5rem 0;
    padding-left: 25px;
    color: white;
}

.side-drawer li:active,
.side-drawer li:focus,
.side-drawer li:hover {
    background-color: #2f57c5;
    color: white;
}

.side-drawer a {
    color: white;
    font-family: "Source Sans Pro", sans-serif;
    text-decoration: none;
    font-size: 17px;
}

.side-drawer a:hover,
.side-drawer a:active {
    color: white;
    text-decoration: none;
}

.icons {
    vertical-align: middle;
    font-size: 20px;
    margin-right: 0.5rem;
}

@media (max-width: 768px) {
    .side-drawer {
        .mobile-only {
            display: block;
        }

        .toolbar_navigation-items a {
            font-size: 1.3rem;
        }
    }

    .side-drawer ul{
        margin-top:12px;
    }
}
